export const PIXCAP_INSTAGRAM_LINK = 'https://www.instagram.com/pixcap_app';
export const PIXCAP_TWITTER_LINK = 'https://twitter.com/pixcap_app';
export const PIXCAP_YOUTUBE_LINK = 'https://www.youtube.com/channel/UCgM4Qz5a3H4n0PBEYI-SLJA';
export const PIXCAP_FACEBOOK_LINK = 'https://www.facebook.com/pixcap';
export const PIXCAP_DISCORD_LINK = 'https://discord.gg/PtR85GHQmu';
export const PIXCAP_TWITCH_LINK = 'https://www.twitch.tv/pixcap';
export const PIXCAP_DOCS_LINK = 'https://pixcap.gitbook.io/pixcap-docs/';
export const PIXCAP_TERMS_LINK = 'https://pixcap.gitbook.io/pixcap-docs/legal/terms';
export const PIXCAP_PRIVACY_LINK = 'https://pixcap.gitbook.io/pixcap-docs/legal/privacy-policy';
export const PIXCAP_TIKTOK_LINK = 'https://www.tiktok.com/@pixcap_app?_t=8YmLYeU6nVF&_r=1';
export const PIXCAP_LINKEDIN_LINK = 'https://www.linkedin.com/company/pixcap/';

export const ENTERPRISE_UPGRADE_LINK = 'https://pixcap.com/enterprise';

export const APP_DATA_THEME_KEY = 'data-theme';
export const APP_DARK_THEME_KEY = 'dark';
export const APP_LIGHT_THEME_KEY = 'light';

export const SOCIAL_AUTH_KEY = 'refreshToken';
export const RECAPTCHA_SITEKEY = '6LfNIeUfAAAAAAc7Jz4QnZrdtiO6R7vk-_wl3DgG';
export const RESEND_EMAIL_TIMEOUT = 10; // value in seconds

export const C_SESSION_COOKIE_KEY = 'c_session';
export const SESSION_ID_COOKIE_KEY = 'session_id';
export const JWT_TOKEN_COOKIE_KEY = 'idToken';
export const SEGMENT_COOKIE_KEY = 'segment_index';
export const GETTING_PRO_PLAN = 'getting_pro_plan';

export const EDITOR_LOADING_TIPS = [
	'Hold <strong>Shift</strong> while <strong>dragging</strong> on timeline to select multiple keyframes.',
	'Press <strong>Ctrl + A</strong> when timeline is last clicked to select all keyframes on timeline.',
	'Quickly create a 3D scene with the <strong>library</strong> and <strong>export</strong> as image for eye-catching 3D illustrations.',
	'<strong>Extract</strong> your animations to add to your own personal animation library.',
	'Get <strong>Pro membership</strong> for access to all templates, animations, poses, characters, and models.',
	'Export as <strong>GLB</strong> for fastest export option.',
];

export const MATERIAL_LIBRARY_SOURCE_QUERY_NAME = 'styles';
export const READY_PLAYER_ME_URL = 'https://pixcap.readyplayer.me/avatar';
export const REQUEST_NEW_ASSETS_URL = 'https://pixcap.canny.io/3d-graphics-requests';
