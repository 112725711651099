
	import { Component, Mixins } from 'vue-property-decorator';
	import SocialAuthMixin from './SocialAuthMixin';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import { actionsWrapper as UserActions, mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';

	@Component({
		name: 'GoogleAutomaticLogin',
	})
	export default class GoogleAutomaticLogin extends Mixins(SocialAuthMixin) {
		clientId = _PIXCAP_ENV.GOOGLE_OAUTH_CLIENT_ID;
		origin = window.origin;

		private loadSdkScript(): Promise<void> {
			return new Promise((resolve, reject) => {
				const js = document.createElement('script') as HTMLScriptElement;
				js.id = 'google-one-tap';
				js.src = 'https://accounts.google.com/gsi/client';
				js.addEventListener('load', () => resolve());
				js.addEventListener('error', () => {
					reject(new Error('Failed to load GoogleOneTapApi gsi script'));
				});
				document.body.appendChild(js);
			});
		}

		triggerLoginPrompt() {
			this.loadSdkScript().then(() => {
				if (this.isAuthenticated) return;
				window.google.accounts.id.initialize({
					client_id: this.clientId,
					auto_select: true,
					use_fedcm_for_prompt: true,
					prompt_parent_id: 'g_id_onload',
					callback: async ({ credential }: { credential: string }) => {
						try {
							const anonymousUser = this.anonymousSession?.session;
							const response = await AuthActions.sendAuthCode(this.$store, {
								code: credential,
								provider: 'google',
								codeType: 'id_token',
								anonymousUser,
							});
							if (response) {
								this.handleSocialAuthMessages(
									{
										data: {
											errorCode: 200,
											message: JSON.stringify(response),
										},
									},
									() => {
										UserActions.fetchPersonalData(this.$store, true);
										UserMutations.showAuthenticationModal(this.$store, false);
									}
								);
								return;
							}
							this.handleSocialAuthMessages({
								data: {
									errorCode: 500,
									message: 'Oops. Error when trying to create your account, please try again.',
								},
							});
							return;
						} catch (error) {
							const errorMessage = error?.data?.errorCode;
							const message = errorMessage?.includes('Primary email is missing')
								? 'Oops. Please register an email for your Facebook or Google account and try again.'
								: 'Oops. Error when trying to create your account, please try again.';
							if (errorMessage?.includes('Primary email is missing')) {
								this.handleSocialAuthMessages({
									data: {
										errorCode: 500,
										message,
									},
								});
								return;
							} else {
								this.handleSocialAuthMessages({
									data: {
										errorCode: 500,
										message,
									},
								});
							}
						}
					},
				});

				window.google.accounts.id.prompt();
			});
		}

		mounted() {
			if (this.isAuthenticated) return;
			this.triggerLoginPrompt();
		}
	}
