
	import { Component, Prop, Vue } from 'vue-property-decorator';

	import { mapGetters, mapState } from 'vuex';
	import {
		CartItem,
		Getters as UserGetters,
		GetterTypes as UserGettersTypes,
		NAMESPACE as USER_NAMESPACE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import MinusCircle from '@pixcap/ui-library/components/Icons/MinusCircle.vue';
	import { LIBRARY_ITEM_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { secondsToMMSS } from '@pixcap/ui-core/utils/DatetimeUtils';
	import { STRIPE_UNSUPPORTED_COUNTRIES } from '@pixcap/ui-core/constants/subscription.constants';
	import IconInfoFilled from '@pixcap/ui-library/components/Icons/IconInfoFilled.vue';

	@Component({
		name: 'UserCartItem',
		components: { IconInfoFilled, MinusCircle, CaptionText, BodyText },
		computed: {
			...mapState(USER_NAMESPACE, {
				cartItems: (state: UserState) => state.cartItems,
			}),
			...mapGetters(USER_NAMESPACE, {
				getPricingForUser: UserGettersTypes.GET_PRICING_FOR_USER,
				overridingPricingCountryCode: UserGettersTypes.OVERRIDING_PRICING_COUNTRY_CODE,
			}),
		},
	})
	export default class UserCartItem extends Vue {
		@Prop() itemInfo: CartItem;

		cartItems: UserState['cartItems'];
		getPricingForUser: ReturnType<UserGetters[UserGettersTypes.GET_PRICING_FOR_USER]>;
		overridingPricingCountryCode: ReturnType<UserGetters[UserGettersTypes.OVERRIDING_PRICING_COUNTRY_CODE]>;

		get isPackType() {
			return !!this.itemInfo.packId;
		}

		get itemId() {
			return this.itemInfo.itemId || this.itemInfo.packId;
		}

		get itemDuration() {
			if (this.itemInfo.animationDuration) {
				return secondsToMMSS(this.itemInfo.animationDuration);
			}
			return null;
		}

		get itemThumbnail() {
			if (typeof this.itemInfo.thumbnailUrl === 'object' && this.itemInfo.thumbnailUrl !== null) {
				return this.itemInfo.thumbnailUrl.url;
			}
			return this.itemInfo.thumbnailUrl as any;
		}

		get itemFileType() {
			switch (this.itemInfo.itemType) {
				case LIBRARY_ITEM_TYPE.ANIMATED_MOCKUP_TEMPLATES:
					return `MP4(720p), ${secondsToMMSS(this.itemInfo.animationDuration)}`;

				case LIBRARY_ITEM_TYPE.DESIGN_TEMPLATES:
				case LIBRARY_ITEM_TYPE.ANIMATED_DESIGN_TEMPLATES:
				case LIBRARY_ITEM_TYPE.STATIC_MOCKUP_TEMPLATES:
					return `PNG (${this.itemInfo.dimension.replace(/\s/g, '')})`;

				case LIBRARY_ITEM_TYPE.SHAPES:
				case LIBRARY_ITEM_TYPE.CHARACTERS:
				case LIBRARY_ITEM_TYPE.ANIMATED_MODELS:
				case LIBRARY_ITEM_TYPE.STATIC_MODELS:
					return `PNG (${this.itemInfo.dimension.replace(/\s/g, '')}), GLB`;
				default:
					return 'PNG (800x800)';
			}
		}

		get hasGLBTooltip() {
			return [LIBRARY_ITEM_TYPE.ANIMATED_DESIGN_TEMPLATES, LIBRARY_ITEM_TYPE.DESIGN_TEMPLATES, LIBRARY_ITEM_TYPE.STATIC_MOCKUP_TEMPLATES].includes(
				this.itemInfo.itemType
			);
		}

		get itemType() {
			switch (this.itemInfo.itemType) {
				case LIBRARY_ITEM_TYPE.ANIMATED_DESIGN_TEMPLATES:
					return 'Animated design';
				case LIBRARY_ITEM_TYPE.DESIGN_TEMPLATES:
					return 'Design';
				case LIBRARY_ITEM_TYPE.ANIMATED_MOCKUP_TEMPLATES:
					return 'Animated mockup';
				case LIBRARY_ITEM_TYPE.STATIC_MOCKUP_TEMPLATES:
					return 'Static mockup';
				case LIBRARY_ITEM_TYPE.CHARACTERS:
					return 'Characters';
				case LIBRARY_ITEM_TYPE.ANIMATED_MODELS:
				case LIBRARY_ITEM_TYPE.STATIC_MODELS:
				default:
					return 'Icon';
			}
		}

		get itemPrice() {
			const PRICING = this.getPricingForUser;
			let currencyCode = PRICING.CURRENCY_PREFIX;
			if (STRIPE_UNSUPPORTED_COUNTRIES.includes(this.overridingPricingCountryCode)) currencyCode = '$';
			return currencyCode + (this.itemInfo.price / 100).toLocaleString('en-US');
		}

		handleRemoveItem() {
			const updatedCartItems = this.cartItems.filter((item) => (this.isPackType ? item.packId !== this.itemId : item.itemId !== this.itemId));
			UserMutations.setCartItems(this.$store, updatedCartItems);
			UserMutations.removeCartItemId(this.$store, this.itemId);
		}
	}
