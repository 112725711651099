export enum API_PATHS {
	ANALYTICS_PATH = 'api/v2/analytics',
	ASSETMANAGER_PATH = 'api/v1/assetmanager',
	AUTH_PATH = 'api/v1/authentication',
	AUTOSAVE_PATH = 'api/v1/autosave',
	PAYMENTS_PATH = 'api/v1/payments',
	COMMENTING_PATH = 'api/v1/comments',
	PERMISSION_PATH = 'api/v1/permissions',
	NOTIFICATIONS_PATH = 'api/v1/notifications',
	BLOG_PATH = 'api/v1/blog',
	BLOG_ARTICLE_PATH = 'api/articles',
	BLOG_CATEGORY_PATH = 'api/categories',
}

export enum MULTIPART_UPLOAD_TYPES {
	FBX = 'fbx',
	GLB = 'glb',
	OBJ = 'obj',
	STL = 'stl',
	BVH = 'bvh',
	ZIP = 'zip',
	BLEND = 'blend',
	VIDEO = 'video',
	IMAGE = 'user_image',
	AUTOSAVE = 'autosave',
	AUTOSAVE_SERVER_STORED_PROPERTY = 'autosave_server_stored_property',
	AVATAR = 'avatar',
	PROJECT_THUMBNAIL = 'project_thumbnail',
	EXPORT_FBX = 'export_fbx',
	EXTRACT_ANIMATION = 'extract_animation',
	EXTRACT_ANIMATION_SUBMISSION = 'extract_animation_submission',
	ANIMATION_VIDEO = 'animation_video',
	FONT = 'font',
	SUBMISSION_THUMBNAIL = 'submission_thumbnail',
	SUBMISSION_TRANSPARENT_THUMBNAIL = 'submission_transparent_thumbnail',
	SUBMISSION_GIF = 'submission_gif',
	PACK_THUMBNAIL = 'pack_thumbnail',
	TEMPLATE_THUMBNAIL = 'template_library_thumbnail',
	TEMPLATE_LIBRARY_FILE = 'template_library_file',
	IMAGE_UPSCALE = 'image_upscale',
	VIDEO_EXPORTS = 'video_exports',
	GIF_EXPORTS = 'gif_exports',
	DEFAULT_IMAGE_EXPORT = 'default_image_export',
	RENDERER_GLB = 'renderer_glb',
	RENDERER_CONFIG = 'renderer_config',
	RENDERER_THUMBNAIL = 'renderer_thumbnail',
	RENDER_VARIANT_INPUT = 'render_variant_input',
	SERVER_RENDER_BACKGROUND = 'renderer_bg',
	USER_BACKGROUND_UPLOAD = 'user_bg_upload',
	RENDERER_FOREGROUND = 'renderer_fg',
	USER_IMAGE = 'user_image',
}
