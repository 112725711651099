export const STORAGE_INVITE_CODE_KEY = 'inviteCodeKey';
export const C_SESSION_COOKIE_KEY = 'c_session';
export const SESSION_ID_COOKIE_KEY = 'session_id';

export const REDIRECT_AUTH_URL_KEY = 'redirect_to';
export const REF_ORIGIN_SOURCE = 'refOrigin';
export const UTM_ORIGIN_SOURCE = 'utmOrigin';

export const QUERY_SETTINGS = 'settings';
export const QUERY_SETTINGS_EMAIL_NOTIFICATIONS = 'email-notifications';
