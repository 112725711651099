import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import {
	NAMESPACE as AUTH_NAMESPACE,
	GetterTypes as AuthGetterTypes,
	Getters as AuthGetters,
	IAuthState,
} from '@pixcap/ui-core/models/store/auth.interface';
import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
import { REF_ORIGIN_SOURCE, UTM_ORIGIN_SOURCE } from '@pixcap/ui-core/constants/shared.constants';
import { mutationsWrapper } from '@pixcap/ui-core/store/auth';
import { openPopUpWindow } from '@pixcap/ui-core/utils/WindowUtils';
import { $notify } from '@pixcap/ui-core/helpers/notification';
import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
import logger from '@pixcap/ui-core/helpers/logger';

@Component({
	name: 'SocialAuthMixin',
	computed: {
		...mapState(AUTH_NAMESPACE, {
			anonymousSession: (state: IAuthState) => state.anonymousSession,
		}),
		...mapGetters(AUTH_NAMESPACE, {
			isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
		}),
	},
})
export default class SocialAuthMixin extends Vue {
	anonymousSession: IAuthState['anonymousSession'];
	isAuthenticated: ReturnType<AuthGetters[AuthGetterTypes.IS_AUTHENTICATED]>;
	registeredListener;

	get isIOSPhone() {
		const userAgent = navigator.userAgent.toLowerCase();
		const pattern = /(ipad|iphone|ipod)/i;
		return pattern.test(userAgent);
	}
	get isAndroidPhone() {
		const userAgent = navigator.userAgent.toLowerCase();
		const pattern = /(android)/i;
		return !this.isIOSPhone && pattern.test(userAgent);
	}
	get redirectURI() {
		return `${origin}/authorizeResult`;
	}
	get isTiktokAgent() {
		const userAgent = navigator.userAgent.toLowerCase();
		const pattern = /(tiktok|bytedance|bytedancewebview)/i;
		return pattern.test(userAgent);
	}
	get isNativeBrowserRuntime() {
		const userAgent = navigator.userAgent.toLowerCase();
		const pattern = /(facebook|instagram|tiktok|bytedance|bytedancewebview|youtube|messenger)/i;
		return pattern.test(userAgent);
	}
	get hideGoogleAuthButton() {
		return this.isNativeBrowserRuntime && (this.isTiktokAgent || this.isIOSPhone);
	}
	get currentFullPath() {
		return this.$route?.fullPath || '/';
	}
	get facebookAuthLink() {
		const anonymousUser = this.anonymousSession?.session;
		const options = {
			redirect_uri: this.redirectURI,
			client_id: _PIXCAP_ENV.FACEBOOK_APP_ID,
			auth_type: 'rerequest',
			response_type: 'code',
			display: 'popup',
			scope: ['email', 'public_profile'].join(' '),
			state: this.isNativeBrowserRuntime ? `facebook_${this.currentFullPath}` : 'facebook',
			anonymousUser,
		};
		const qs = new URLSearchParams(options);
		return `${_PIXCAP_ENV.FACEBOOK_BASE_URL}?${qs.toString()}`;
	}

	get googleAuthLink() {
		const anonymousUser = this.anonymousSession?.session;
		const options = {
			redirect_uri: this.redirectURI,
			client_id: _PIXCAP_ENV.GOOGLE_OAUTH_CLIENT_ID,
			access_type: 'offline',
			response_type: 'code',
			prompt: 'consent',
			scope: ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'].join(' '),
			state: this.isNativeBrowserRuntime ? `google_${this.currentFullPath}` : 'google',
			anonymousUser,
		};
		const qs = new URLSearchParams(options);
		return `${_PIXCAP_ENV.GOOGLE_BASE_URL}?${qs.toString()}`;
	}

	beforeDestroy() {
		if (this.registeredListener) {
			window.removeEventListener('message', this.registeredListener);
		}
	}
	navigate(url: string, onLoginSuccessfully?: () => void) {
		if (this.isNativeBrowserRuntime) {
			const urlDomain = url.replace('https://', '');
			if (this.isAndroidPhone) {
				const intentLink = `intent://${urlDomain}#Intent;scheme=https;end`;
				window.open(intentLink);
			} else if (this.isIOSPhone) {
				window.open(url, '_blank');
			}
			return;
		}
		openPopUpWindow(url, 600, 600);
		this.registeredListener = (e) => this.handleSocialAuthMessages(e, onLoginSuccessfully);
		window.addEventListener('message', this.registeredListener);
	}

	handleSocialAuthMessages = (e, onLoginSuccessfully?: () => void) => {
		const messageData = e.data;
		if (messageData && messageData.errorCode) {
			if (messageData.errorCode === 500) {
				$notify({
					type: NotificationType.ERROR,
					title: messageData.message,
				});
			} else if (messageData.errorCode === 200) {
				const { isSocialAuthSignUp, userId, email, idToken } = JSON.parse(messageData.message);
				const callback = () => {
					if (this.$pixcap.$crisp) this.$pixcap.$crisp('login', userId, email);
					mutationsWrapper.store(this.$store, { userId, idToken });
					if (onLoginSuccessfully) {
						onLoginSuccessfully();
					}
				};

				const refOriginSource = localStorage.getItem(REF_ORIGIN_SOURCE);
				const utmOriginSource = localStorage.getItem(UTM_ORIGIN_SOURCE);
				if (refOriginSource) localStorage.removeItem(REF_ORIGIN_SOURCE);
				if (isSocialAuthSignUp) {
					this.$pixcap.$mixpanel.alias(userId);
					this.$pixcap.$mixpanel.identify(userId);
					this.$pixcap.$mixpanel.people.set({ $email: email });
					this.$pixcap.$mixpanel.track(
						'Sign Up',
						{ Method: 'Social Auth', 'Ref Origin Source': refOriginSource, 'Utm Origin Source': utmOriginSource },
						callback
					);
					try {
						this.$pixcap.$GTM_dataLayer('new_sign_up', email);
					} catch (err) {
						logger.error(err);
					}
				} else {
					this.$pixcap.$mixpanel.identify(userId);
					this.$pixcap.$mixpanel.track('Login', { 'Login Method': 'Social Auth' }, callback);
				}
			}
		}
	};
}
