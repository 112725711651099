var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"server-download-popup-container"},[_c('Popup',{ref:"refServerDownloadPopup",attrs:{"is-custom-toggle":true},on:{"closed":_vm.onClosePopup,"onOpen":_vm.onOpenPopup},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
					content: _vm.showCompletedTooltip
						? _vm.$t
							? _vm.$t('navbar.download_dropdown.tooltip.download_completed')
							: 'Download completed'
						: _vm.$t
						? _vm.$t('navbar.download_dropdown.tooltip.download_history')
						: 'Download history',
					show: _vm.showCompletedTooltip ? _vm.showCompletedTooltip : false,
					trigger: _vm.showCompletedTooltip ? 'manual' : 'hover',
					classes: _vm.showCompletedTooltip ? 'v-tooltip-color-tertiary z-index-1' : undefined,
					container: _vm.container,
				}),expression:"{\n\t\t\t\t\tcontent: showCompletedTooltip\n\t\t\t\t\t\t? $t\n\t\t\t\t\t\t\t? $t('navbar.download_dropdown.tooltip.download_completed')\n\t\t\t\t\t\t\t: 'Download completed'\n\t\t\t\t\t\t: $t\n\t\t\t\t\t\t? $t('navbar.download_dropdown.tooltip.download_history')\n\t\t\t\t\t\t: 'Download history',\n\t\t\t\t\tshow: showCompletedTooltip ? showCompletedTooltip : false,\n\t\t\t\t\ttrigger: showCompletedTooltip ? 'manual' : 'hover',\n\t\t\t\t\tclasses: showCompletedTooltip ? 'v-tooltip-color-tertiary z-index-1' : undefined,\n\t\t\t\t\tcontainer: container,\n\t\t\t\t}",modifiers:{"bottom":true}}]},[_c('ButtonIcon',{staticClass:"export-button",class:{ active },attrs:{"variant":"outline","color":"neutral","size":"xsmall"},nativeOn:{"click":function($event){return _vm.togglePopup.apply(null, arguments)}}},[(_vm.isExporting)?_c('div',{staticClass:"export-progress-icon"},[_c('div',{staticClass:"progress-icon__count"},[_vm._v(_vm._s(_vm.countExportingItems))]),_vm._v(" "),_c('div',{staticClass:"progress-icon__wrapper"},[_c('IconLargeArrowLeft',{attrs:{"width":6,"fill":"#fff"}})],1)]):(!_vm.isOpenedQueueWhenCompleted)?_c('IconCheckMark',{staticClass:"completed-mark",attrs:{"width":_vm.iconSize,"height":_vm.iconSize}}):_c('IconQueue',{attrs:{"width":_vm.iconSize,"height":_vm.iconSize,"fill":"var(--pixcap-neutral-700)"}})],1)],1)]}},{key:"content",fn:function(){return [_c('PixRenderQueuePopup',{staticClass:"server-download-popup",on:{"closePopup":_vm.togglePopup}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }