
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import Popup from '@pixcap/ui-library/components/Popup.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import {
		GetterTypes as UserGettersTypes,
		Getters as UserGetters,
		NAMESPACE as USER_NAMESPACE,
		UserState,
		SUBSCRIPTION,
		ContactModalType,
		PAYMENT_STATUS,
		MY_ACCOUNT_TAB,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import IconGift from '@pixcap/ui-library/components/Icons/IconGift.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';

	@Component({
		name: 'UserInfoAvatar',
		components: {
			HeadingText,
			IconGift,
			Popup,
			BodyText,
			CaptionText,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
				subscriptionInfo: (state: UserState) => state.subscriptionInfo,
			}),
			...mapGetters(USER_NAMESPACE, {
				isReviewer: UserGettersTypes.IS_REVIEWER,
				isContributor: UserGettersTypes.IS_CONTRIBUTOR,
			}),
		},
	})
	export default class UserInfoAvatar extends Vue {
		@Prop({ default: 'md' }) readonly size: 'lg' | 'md' | 'md2';
		@Prop({ default: false }) readonly isRedirect: boolean;
		@Prop({ default: false }) readonly isFromExploreApp: boolean;
		@Prop({ default: false }) navigateInNewTab: boolean;

		personalData: UserState['personalData'];
		subscriptionInfo: UserState['subscriptionInfo'];

		isReviewer: ReturnType<UserGetters[UserGettersTypes.IS_REVIEWER]>;
		isContributor: ReturnType<UserGetters[UserGettersTypes.IS_CONTRIBUTOR]>;

		get userInfo() {
			return this.personalData.user;
		}

		get isCMSDashboardActive() {
			return this.$route.matched.some((route) => route.meta?.isSubmission);
		}

		get subscription() {
			return this.subscriptionInfo?.subscription;
		}

		get subscriptionStatus() {
			return this.subscriptionInfo?.paymentStatus;
		}

		get userPlanText() {
			if (this.subscription == SUBSCRIPTION.BASIC) return null;
			if (this.subscriptionStatus != PAYMENT_STATUS.ACTIVE) return null;
			return this.subscription === SUBSCRIPTION.PRO_ANNUALLY ||
				this.subscription === SUBSCRIPTION.PRO_MONTHLY ||
				this.subscription == SUBSCRIPTION.PRO_QUARTERLY
				? 'PRO plan'
				: 'ELITE plan';
		}

		handleLogOut() {
			this.$emit('close');
			AuthActions.logout(this.$store, { sessionChange: false });
		}

		toggleContactUSModal() {
			UserMutations.setShowContactModal(this.$store, ContactModalType.DEFAULT);
		}

		navigate(link) {
			if (this.navigateInNewTab) window.open(link, '_blank');
			else window.location.href = link;
		}
	}
