import {
	MY_ACCOUNT_TAB,
	UNSUBSCRIBE_EMAIL_OPTIONS,
	TEmailNotificationOptions,
	EmailNotifications,
} from '@pixcap/ui-core/models/store/user.interface';

export const AVATAR_MIME_TYPE = 'image/jpeg';
export const AVATAR_SIZE_LIMIT = 5 as const; // Unit: MB
export const AVATAR_MIN_WIDTH = 200 as const; // Unit: px

export const TEXT_TABS_SHOW = {
	[MY_ACCOUNT_TAB.ACCOUNT_SETTINGS]: { title: MY_ACCOUNT_TAB.ACCOUNT_SETTINGS, description: '' },
	[MY_ACCOUNT_TAB.INVITE_FRIENDS]: {
		title: 'Invite friends, get rewards instantly',
		description: 'Share the invitation link with your friends and track your earned rewards.',
	},
	[MY_ACCOUNT_TAB.SUBSCRIPTION]: { title: MY_ACCOUNT_TAB.SUBSCRIPTION, description: 'Track your plans & credits' },
	[MY_ACCOUNT_TAB.PROMOTIONS]: { title: MY_ACCOUNT_TAB.PROMOTIONS, description: 'Track referral discounts & weekly freebies.' },
	[MY_ACCOUNT_TAB.CHANGE_PASSWORD]: { title: MY_ACCOUNT_TAB.CHANGE_PASSWORD, description: '' },
	[MY_ACCOUNT_TAB.EMAIL_NOTIFICATIONS]: { title: MY_ACCOUNT_TAB.EMAIL_NOTIFICATIONS, description: '' },
};

export const INIT_EMAIL_NOTIFICATIONS = Object.values(UNSUBSCRIBE_EMAIL_OPTIONS).reduce((acc, value: string) => {
	acc[value] = true;
	return acc;
}, {} as EmailNotifications);

export const COUNTRY_CODES = {
	ANDORRA: 'AD',
	AFGHANISTAN: 'AF',
	ALGERIA: 'DZ',
	ANGOLA: 'AO',
	ARGENTINA: 'AR',
	ARMENIA: 'AM',
	AUSTRALIA: 'AU',
	AUSTRIA: 'AT',
	BAHRAIN: 'BH',
	BANGLADESH: 'BD',
	BELGIUM: 'BE',
	BHUTAN: 'BT',
	BOLIVIA: 'BO',
	BOTSWANA: 'BW',
	BRAZIL: 'BR',
	BULGARIA: 'BG',
	BURUNDI: 'BI',
	CAMBODIA: 'KH',
	CANADA: 'CA',
	CHILE: 'CL',
	CHINA: 'CN',
	COLOMBIA: 'CO',
	CROATIA: 'HR',
	CYPRUS: 'CY',
	CZECH_REPUBLIC: 'CZ',
	DENMARK: 'DK',
	ECUADOR: 'EC',
	EGYPT: 'EG',
	ETHIOPIA: 'ET',
	ESTONIA: 'EE',
	FINLAND: 'FI',
	FRANCE: 'FR',
	GERMANY: 'DE',
	GEORGIA: 'GE',
	GREECE: 'GR',
	HUNGARY: 'HU',
	ICELAND: 'IS',
	ISRAEL: 'IL',
	INDIA: 'IN',
	INDONESIA: 'ID',
	IRAN: 'IR',
	IRAQ: 'IQ',
	ITALY: 'IT',
	JAPAN: 'JP',
	JORDAN: 'JO',
	KAZAKHSTAN: 'KZ',
	KENYA: 'KE',
	KYRGYZSTAN: 'KG',
	KUWAIT: 'KW',
	LAOS: 'LA',
	LATVIA: 'LV',
	LEBANON: 'LB',
	LICHTENSTEIN: 'LI',
	LIBYA: 'LY',
	LITHUANIA: 'LT',
	LUXEMBOURG: 'LU',
	MADAGASCAR: 'MG',
	MALTA: 'MT',
	MALAWI: 'MW',
	MALAYSIA: 'MY',
	MALDIVES: 'MV',
	MAURITIUS: 'MU',
	MEXICO: 'MX',
	MONACO: 'MC',
	MONGOLIA: 'MN',
	MORROCO: 'MA',
	MOZAMBIQUE: 'MZ',
	MYANMAR: 'MM',
	NAMIBIA: 'NA',
	NEPAL: 'NP',
	NETHERLANDS: 'NL',
	NIGERIA: 'NG',
	PAKISTAN: 'PK',
	PERU: 'PE',
	PHILIPPINES: 'PH',
	PORTUGAL: 'PT',
	RUSSIA: 'RU',
	RWANDA: 'RW',
	SAUDI_ARABIA: 'SA',
	SEYCHELLES: 'SC',
	SINGAPORE: 'SG',
	SOUTH_AFRICA: 'ZA',
	SRI_LANKA: 'LK',
	SLOVAKIA: 'SK',
	SLOVENIA: 'SI',
	SPAIN: 'ES',
	SYRIA: 'SY',
	TAJIKSTAN: 'TJ',
	TANZANIA: 'TZ',
	THAILAND: 'TH',
	TUNISIA: 'TN',
	TURKEY: 'TR',
	TURKMENISTAN: 'TM',
	NEW_ZEALAND: 'NZ',
	NORWAY: 'NO',
	OMAN: 'OM',
	POLAND: 'PL',
	QATAR: 'QA',
	ROMANIA: 'RO',
	SOUTH_KOREA: 'KR',
	SWEDEN: 'SE',
	SWITZERLAND: 'CH',
	UGANDA: 'UG',
	UKRAINE: 'UA',
	UNITED_ARAB_EMIRATES: 'AE',
	UNITED_KINGDOM: 'GB',
	UNITED_STATES: 'US',
	UZBEKISTAN: 'UZ',
	VENEZUELA: 'VE',
	VIETNAM: 'VN',
	YEMEN: 'YE',
	ZAMBIA: 'ZM',
	ZIMBABWE: 'ZW',
};
