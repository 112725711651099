
	import { Component, InjectReactive, Prop, Vue, Watch } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import Popup from '@pixcap/ui-library/components/Popup.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import {
		CartItemType,
		Getters as UserGetters,
		GetterTypes as UserGettersTypes,
		NAMESPACE as USER_NAMESPACE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import IconCart from '@pixcap/ui-library/components/Icons/IconCart.vue';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';

	import Modal from '@pixcap/ui-library/components/Modal.vue';
	import UserCartItem from '@pixcap/ui-core/components/account/common/UserCartItem.vue';
	import { actionsWrapper as UserActions, mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';

	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconCircleNotch from '@pixcap/ui-library/components/Icons/IconCircleNotch.vue';
	import { STRIPE_UNSUPPORTED_COUNTRIES } from '@pixcap/ui-core/constants/subscription.constants';

	@Component({
		name: 'UserCart',
		components: {
			IconCircleNotch,
			Button,
			UserCartItem,
			IconCart,
			Popup,
			BodyText,
			CaptionText,
			Modal,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				cartItems: (state: UserState) => state.cartItems,
				cartItemsId: (state: UserState) => state.cartItemsId,
				isFetchingCartItems: (state: UserState) => state.isFetchingCartItems,
				personalData: (state: UserState) => state.personalData,
			}),
			...mapGetters(USER_NAMESPACE, {
				getPricingForUser: UserGettersTypes.GET_PRICING_FOR_USER,
				overridingPricingCountryCode: UserGettersTypes.OVERRIDING_PRICING_COUNTRY_CODE,
			}),
		},
	})
	export default class UserCart extends Vue {
		@InjectReactive() isMobileScreen: boolean;

		isFetchingCartItems: UserState['isFetchingCartItems'];
		cartItems: UserState['cartItems'];
		cartItemsId: UserState['cartItemsId'];
		personalData: UserState['personalData'];
		getPricingForUser: ReturnType<UserGetters[UserGettersTypes.GET_PRICING_FOR_USER]>;
		overridingPricingCountryCode: ReturnType<UserGetters[UserGettersTypes.OVERRIDING_PRICING_COUNTRY_CODE]>;

		emptyCart = `${_PIXCAP_ENV.ROOT_URL}/cdn/app/cart/cart-empty.webp`;
		showCart = false;
		watchFlag = 0;

		$refs: {
			refEmptyCartPopup: Popup;
			refCartItemList: HTMLElement;
		};

		@Watch('cartItemCount')
		handleOpenCart(val, oldVal) {
			if (val === 0) {
				this.showCart = false;
				return;
			}
			if (val > oldVal && this.watchFlag > 0) {
				this.handleShowCart();
			}
		}

		@Watch('purchasedItems', { immediate: true })
		@Watch('cartItemsId', { immediate: true })
		handleCheck() {
			this.handleRemovePurchasedItem();
		}

		get purchasedItems() {
			return this.personalData?.user?.purchaseHistory;
		}

		get cartItemCount() {
			return this.cartItemsId.length;
		}

		get totalPrice() {
			const PRICING = this.getPricingForUser;
			let currencyCode = PRICING.CURRENCY_PREFIX;
			if (STRIPE_UNSUPPORTED_COUNTRIES.includes(this.overridingPricingCountryCode)) currencyCode = '$';
			let total = 0;
			this.cartItems.forEach((item) => (total += item.price));
			return currencyCode + (total / 100).toLocaleString('en-US');
		}

		async fetchCartItems() {
			const itemsId = this.cartItemsId.filter((item) => item.itemType === CartItemType.ITEM).map((item) => item.itemId);
			const packsId = this.cartItemsId.filter((item) => item.itemType === CartItemType.PACK).map((item) => item.itemId);
			await UserActions.fetchCartItems(this.$store, { itemIds: itemsId, packIds: packsId });
			this.$nextTick(() => {
				this.$refs.refCartItemList?.scrollTo({ top: this.$refs.refCartItemList.scrollHeight, behavior: 'smooth' });
			});
		}

		async handleShowCart() {
			this.showCart = true;
			if (this.cartItemCount === 0) {
				this.$refs.refEmptyCartPopup?.toggle();
				return;
			}
			await this.fetchCartItems();
		}

		handleCloseCart() {
			this.showCart = false;
		}

		handleRemovePurchasedItem() {
			this.cartItemsId.forEach((item) => {
				if (this.purchasedItems.includes(item.itemId)) {
					UserMutations.removeCartItemId(this.$store, item.itemId);
				}
			});
		}

		handleCheckout() {
			this.handleCloseCart();
			UserMutations.setShowCartPaymentMethodModal(this.$store, { isOpen: true });
		}

		mounted() {
			setTimeout(() => {
				this.watchFlag = 1;
			}, 500);
		}
	}
