import { PixRenderExportStatus, PixRenderProgressStatus } from '@pixcap/ui-core/models/store/projectexports.interface';

export const MAX_FRAMES_GIF_EXPORTING = 500;
export const MAX_FRAMES_MP4_EXPORTING = 500;
export const MAX_FRAMES_PROJECT_EXPORTING = 500;

export const EXPORT_1K_HEIGHT = 720;
export const EXPORT_1K_WIDTH = 1024;
export const EXPORT_4K_HEIGHT = 2160;
export const EXPORT_4K_WIDTH = 4096;

export function PROGRESS_STATUS_MAPPING(pixRenderProgressStatus: PixRenderProgressStatus) {
	switch (pixRenderProgressStatus) {
		case PixRenderProgressStatus.FINISH:
			return PixRenderExportStatus.COMPLETED;
		case PixRenderProgressStatus.DONE:
			return PixRenderExportStatus.DONE;
		case PixRenderProgressStatus.CANCELLED:
			return PixRenderExportStatus.CANCELLED;
		case PixRenderProgressStatus.FAILED:
		case PixRenderProgressStatus.ERROR:
			return PixRenderExportStatus.FAILED;
		case PixRenderProgressStatus.REMOVED:
			return PixRenderExportStatus.REMOVED;
		default:
			return PixRenderExportStatus.IN_PROGRESS;
	}
}

export const MAX_PIX_RENDER_JOBS = 3;
export const STANDARD_GIF_FPS = 25;
